<template>
	<div class="wrapper" :class="{'wrapper__no-scroll' : isAuth}">
		<div class="bg" :class="{'bg__2' : (currentStep > 0 && currentStep < 8), 'bg__3' : currentStep > 7}"></div>
		<div class="logo"><a href="#"></a></div>
		<StartStep @startTest="startTest" v-if="currentStep === 0"/>
		<component
			v-show="currentStep === (index+1)"
			v-bind:is="question.type"
			v-for="(question, index) in questions"
			:balance.sync="balance"
			:item='question'
			:num='(index+1)'
			:key="question.id"
			@choseAnswer="choseAnswer"
		></component>
		<FinishStep @startTest="startTest" @share="share" :correct-answers="correctAnswers" v-if="currentStep === 8"/>
		<div class="copy">
			<span>© «СДМ-Банк» (ПАО), 2013-{{ new Date().getFullYear() }}. <span>Генеральная лицензия Банка России №1637</span></span>
			<span><a href="/upload/files/sogl.pdf" target="_blank">Согласие на обработку персональных данных</a></span>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import StartStep from './components/StartStep.vue'
import RadioQuestion from './components/RadioQuestion.vue'
import YesOrNo from "@/components/YesOrNo";
import FinishStep from "@/components/FinishStep";

export default {
	name: 'App',
	components: {
		StartStep,
		RadioQuestion,
		YesOrNo,
		FinishStep,
	},
	data() {
		return {
			id: 0,
			param: window.sdm_test,
			isAuth: false,
			startBalance: 100000,
			balance: 100000,
			currentStep: 0,
			questions: [
				{
					title: 'Вам звонит сотрудник банка, представляется и называет вас по имени. Он сообщает, что с вашей банковской карты проходят подозрительные операции. Предлагает вам защитить ваш счёт и просит продиктовать три цифры на обороте карты. Ваши действия?',
					type: 'RadioQuestion',
					answers: [
						{
							title: 'Предоставляете сотруднику все данные, которые он запросит.',
							correct: false,
						},
						{
							title: 'Начнете паниковать и просить сотрудника помочь.',
							correct: false,
						},
						{
							title: 'Молча заканчиваете разговор и самостоятельно перезваниваете в свой банк, чтобы выяснить происходящее.',
							correct: true,
						},
					],
					errorMark: 15000
				},
				{
					title: 'Это было легко! Немного усложняем задачу. Выберите сайт, которому вы доверитесь и оформите билеты на самолёт.',
					type: 'RadioQuestion',
					answers: [
						{
							title: 'www.aviasales.ru',
							correct: true,
						},
						{
							title: 'www.aviasales$.ru',
							correct: false,
						},
					],
					errorMark: 10000
				},
				{
					title: 'Надеемся, что вас можно поздравить, и вы отправляетесь в путешествие! Пора собирать чемоданы, попросить соседей полить цветы и подготовить банковскую карту. Сразу вопрос: подключаем уведомления обо всех операциях по вашей карте?',
					type: 'YesOrNo',
					answers: [
						{
							title: 'yes',
							correct: true,
						},
						{
							title: 'no',
							correct: false,
						},
					],
					errorMark: 10000
				},
				{
					title: 'Ваш самолёт приземлился в жаркой стране. По прилету вы сразу решили обследовать местность и закупиться фруктами. На рынке принимают только наличные. Вы нашли отделение банка, но не понимаете, как обращаться с заграничной техникой. Ваши действия:',
					type: 'RadioQuestion',
					answers: [
						{
							title: 'Вопрос был быстро решен, так как мне предложил помощь добрый незнакомец. Правда, получилось снять не ту сумму, которую нужно. Но это, наверное, местная комиссия.',
							correct: false,
						},
						{
							title: 'Обращусь к сотрудникам банка. Они должны мне рассказать, как совершить операции в их банке и банкоматах.',
							correct: true,
						},
						{
							title: 'Уже вкушаю экзотические фрукты. Взял с собой немного наличных, как раз на такие случаи.',
							correct: true,
						},
					],
					errorMark: 20000
				},
				{
					title: 'Отпуск окончен, и вы уже дома. Вам в личные сообщения в соцсетях пишет некая милая девушка. Она собирает деньги на лечение Мурзика. В сообщении указаны номера карт, на которые можно перевести деньги. Как поступите?',
					type: 'RadioQuestion',
					answers: [
						{
							title: 'Сразу же отправлю деньги. Котика нужно спасать.',
							correct: false,
						},
						{
							title: 'Сделаю репост в соцсетях, но деньги не переведу.',
							correct: false,
						},
						{
							title: 'Постараюсь проверить информацию доступными способами: для начала пропущу фотографию Мурзика через поисковик.',
							correct: true,
						},
					],
					errorMark: 15000
				},
				{
					title: 'Вы листаете ленту соцсетей дальше и натыкаетесь на распродажу модной одежды по очень низким ценам. Менеджер магазина сообщает, что товары продаются только при полной предоплате. Берём?',
					type: 'YesOrNo',
					answers: [
						{
							title: 'yes',
							correct: false,
						},
						{
							title: 'no',
							correct: true,
						},
					],
					errorMark: 10000
				},
				{
					title: 'Вам приходит СМС с каким-то кодом. Через несколько минут раздается звонок — сотрудник банка. Он говорит, что вашу карточку взломали мошенники, и чтобы их остановить, нужно назвать тот самый код. Что скажете?',
					type: 'RadioQuestion',
					answers: [
						{
							title: 'Брошу трубку и свяжусь с банком самостоятельно.',
							correct: true,
						},
						{
							title: 'Мою карточку взломали, нужно действовать быстро. Назову код сотруднику.',
							correct: false,
						},
						{
							title: 'Поинтересуюсь, как этот код поможет остановить мошенников. Если ответ меня убедит, предоставлю цифры из СМС.',
							correct: false,
						},
					],
					errorMark: 20000
				},
			],
			answers: [],
			correctAnswers: 0,
		}
	},
	watch: {
		currentStep(val) {
			if (this.param.ajaxUrl) {
				axios
					.post(this.param.ajaxUrl, {
						id: this.id,
						questions: this.questions,
						answers: this.answers,
						correctAnswers: this.correctAnswers,
						balance: this.balance,
					})
					.then(response => {
						this.id = response.data.ID;
						if (val === 8 && response.data.URL) {
							location.href = response.data.URL;
						}
					})
					.catch(error => {
						console.error(error);
					})
				// console.log('send result to server');
			}
			if (val > 8)
				this.currentStep = 0;
		},
	},
	methods: {
		startTest() {
			if (!this.param.isAuth) {
				this.showAuth();
				return false;
			}
			this.balance = this.startBalance;
			this.correctAnswers = 0;
			this.answers = [];
			this.currentStep = 1;
			this.id = 0;
		},
		showAuth() {
			this.isAuth = true;
			let overlay = document.getElementsByClassName('overlay');
			if (overlay.length > 0) {
				overlay[0].style.display = ''
			}
			let modal = document.getElementsByClassName('modal');
			if (modal.length > 0) {
				modal[0].style.display = ''
			}
		},
		choseAnswer(question, answer) {
			// console.log(question.type, question, answer);
			switch (question.type) {
				case 'RadioQuestion':
					if (!answer.correct)
						this.balance -= question.errorMark;
					else
						this.correctAnswers++;
					this.answers.push(answer);
					break;
				case 'YesOrNo':
					var answ = question.answers.filter(answerItem => answerItem.title === answer);
					if (answ.length) {
						console.log(answ);
						answ = answ[0];
						if (!answ.correct)
							this.balance -= question.errorMark;
						else
							this.correctAnswers++;
						this.answers.push(answ);
					}
					break;
				default:
					alert("Неизвестный тип вопроса");
					return;
			}
			this.currentStep++;
		},
		share() {
			// let social = window.sdm_test.social
			// let url = "/test/result/" + this.correctAnswers + "/";
			let url = this.param.root + "/test/" + this.id;
			let urlSoc = '';
			switch (this.param.social){
				case 'vk':
					urlSoc = "https://vk.com/share.php?";
					urlSoc += "url=" + url;
					window.open(urlSoc, "vk", "width=650,height=570");
					break;
				case 'fb':
					urlSoc = "https://www.facebook.com/sharer.php?src=sp&";
					urlSoc += "u=" + url;
					window.open(urlSoc, "fb", "width=650,height=570");
					break;
				case 'ok':
					urlSoc = "https://connect.ok.ru/offer?";
					urlSoc += "url=" + url;
					// urlFb += "&";
					// urlFb += "quote=" + text;
					window.open(urlSoc, "ok", "width=650,height=570");
					break;
				case 'tw':
					urlSoc = "https://twitter.com/intent/tweet?";
					urlSoc += "url=" + url;
					// urlFb += "&";
					// urlFb += "quote=" + text;
					window.open(urlSoc, "tw", "width=650,height=570");
					break;
				default:
					alert("Неизвестный тип соц сети");
					return;
			}
			return true;
		}
	}
}
</script>

<style scoped>
@media (min-width:768px) {
	.copy {
		display: flex;
		justify-content: space-between;
	}
}
</style>
