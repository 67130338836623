<template>
	<div class="form">
		<div class="label"><span>Вопрос {{ num }}</span></div>
		<div class="balance">
			<div class="balance_one">Ваш баланс</div>
			<div class="balance_two">{{ balance }}</div>
		</div>
		<div class="text-2">{{item.title}}</div>
		<div class="choose">
			<label
				class="choose_item"
				v-for="(answer, index) in item.answers"
				:key="index"
				>
				<input type="radio" name="radio" @change="chose(item, answer)">
				<span>{{answer.title}}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "RadioQuestion",
	props: {
		item: {
			type: Object,
			// default: {}
		},
		balance: {
			type: Number,
		},
		num: {
			type: Number,
		}
	},
	methods: {
		chose(item, answer) {
			setTimeout(()=>{
				this.$emit('choseAnswer', item, answer);
			}, 500);
		},
	}
}
</script>

<style scoped>

</style>