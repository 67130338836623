import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production'){
	window.sdm_test = {
		isAuth: true,
		social: 'vk',
		root: 'http://security-sdm.dev.laconix.com',
		ajaxUrl: 'http://security-sdm.dev.laconix.com/api/ajax.php',
	}
}

new Vue({
	render: h => h(App),
}).$mount('#app')
