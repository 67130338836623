<template>
	<div class="form">
		<div class="label"><span>Тест</span></div>
		<div class="block-1"><i>«Алло, нам нужны ваши деньги!»</i><b> — сможете ли вы распознать мошенника?</b>
		</div>
		<div class="text-1">В век высоких технологий мошенники могут украсть ваши деньги, не вставая с дивана!
			Давайте
			проверим, сможете ли вы защитить свои сбережения!
		</div>
		<div class="text-1">На время теста мы дарим вам 100 000 рублей. За каждый неправильный ответ мошенник будет
			получать энную сумму с «вашего» счёта. Будьте внимательны!
		</div>
		<button type="button" class="button" @click.prevent="$emit('startTest')">Пройти тест</button>
	</div>
</template>

<script>
export default {
	name: 'StartStep',
}
</script>

<style scoped>
</style>
