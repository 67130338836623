<template>
	<div class="form">
		<div class="label"><span>Результат</span></div>
		<div class="banner" :class="{fail:correctAnswers < 7}"><span>{{ correctAnswers }}/7</span></div>

		<div class="text-3" v-if="correctAnswers >= 7">Бдительный гражданин</div>
		<div class="text-3" v-else>Потенциальная жертва мошенников</div>

		<div class="text-4" v-if="correctAnswers >= 7"><b>Поздравляем, вы не попались на уловки мошенников и ваши денежки остались при вас.</b></div>
		<div class="text-4" v-else><b>У вас есть шансы подарить сбережения преступникам. Мы надеемся, если вам позвонят мошенники, вы вспомните этот тест и не станете следовать их просьбам.</b></div>

		<div class="text-4">Подробнее с правилами безопасности вы можете ознакомиться на сайте <a href="https://www.sdm.ru/online-services/security-recommendations/" target="_blank">www.sdm.ru</a></div>
		<div class="socials">
			<button type="button" class="socials_item socials_item__1" @click.prevent="$emit('share')">Поделиться результатом с друзьями</button>
			<button type="button" class="socials_item socials_item__2" @click.prevent="$emit('startTest')">Пройти тест еще раз</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "FinishStep",
	props: {
		correctAnswers: {
			type: Number,
		}
	},
	methods: {
	}
}
</script>

<style scoped>
</style>