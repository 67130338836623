<template>
	<div class="form">
		<div class="label"><span>Вопрос {{ num }}</span></div>
		<div class="balance">
			<div class="balance_one">Ваш баланс</div>
			<div class="balance_two">{{ balance }}</div>
		</div>
		<div class="text-2">{{item.title}}</div>
		<div class="thing" :class="classThing">
			<div class="thing_toggle">
				<button type="button" class="thing_button thing_button__left" @click.prevent="chose(item, 'yes')"></button>
				<button type="button" class="thing_button thing_button__right" @click.prevent="chose(item, 'no')"></button>
			</div>
			<div class="thing_labels">
				<div @click.prevent="chose(item, 'yes')">Да</div>
				<div @click.prevent="chose(item, 'no')">Нет</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "YesOrNo",
	props: {
		item: {
			type: Object,
			// default: {}
		},
		balance: {
			type: Number,
		},
		num: {
			type: Number,
		}
	},
	data() {
		return {
			classThing: ''
		}
	},
	methods: {
		chose(item, type) {
			switch (type) {
				case 'yes':
					this.classThing = 'left active';
					break;
				case 'no':
					this.classThing = 'right active';
					break;
				default:
					alert( "Неизвестный тип ответа" );
					return;
			}
			setTimeout(()=>{
				this.$emit('choseAnswer', item, type);
				this.classThing = '';
			}, 500);
		},
	}
}
</script>

<style scoped>

</style>